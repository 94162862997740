<table class="table table-striped mt-3">
  <thead>
    <tr>
      <th scope="col">{{ 'Id' }}</th>
      <th scope="col">{{ 'Фото' }}</th>
      <th scope="col">{{ 'Назва' }}</th>
      <th scope="col">{{ 'Статус' }}</th>
      <th scope="col">{{ 'Адреса' }}</th>
      <th scope="col">{{ 'Локація' }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let touristLocation of touristLocations">
      <td>{{ touristLocation.Id }}</td>
      <td>
        <mat-icon *ngIf="touristLocation.S3Link">check</mat-icon>
      </td>
      <td>{{ touristLocation.TitleMultiLanguage.uk }}</td>
      <td>
        {{ touristLocation.Active === true ? 'Активна' : 'Неактивна' }}
      </td>
      <td>{{ touristLocation.AddressMultiLanguage.uk }}</td>
      <td>
        <a
          href="https://maps.google.com/?q={{ touristLocation.Location.lat }},{{ touristLocation.Location.lng }}"
          target="_blank"
        >
          <{{ touristLocation.Location.lat }}, {{ touristLocation.Location.lng }}>
        </a>
      </td>
      <td>
        <button class="mat-raised-button custom-btn">
          <mat-icon (click)="deleteTourLocation(touristLocation.UserId, touristLocation.ItemId)" class="pointer">
            delete
          </mat-icon>
        </button>
        <button class="mat-raised-button custom-btn">
          <mat-icon (click)="editTourLocation(touristLocation)" class="pointer"> edit</mat-icon>
        </button>
      </td>
    </tr>
  </tbody>
</table>
