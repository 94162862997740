import { SupportedLanguagesModel } from '../models/i-supported-languages.model';

export const supportedLanguagesConfig: SupportedLanguagesModel[] = [
  {
    code: 'uk',
    label: 'Українська',
  },
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'pl',
    label: 'Polski',
  },
  {
    code: 'sl',
    label: 'Slovenský',
  },
  {
    code: 'ro',
    label: 'Română',
  },
  {
    code: 'fr',
    label: 'Français',
  },
];
