import { Component, Input, OnDestroy } from '@angular/core';
import { TourTemplateResponseModel } from '../../models/tour-template.model';
import { TouristTemplateTableComponent } from './features/tourist-template-table/tourist-template-table.component';
import { FormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { TouristTemplateService } from '../../services/tourist-template.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-tourist-template-view',
  standalone: true,
  imports: [TouristTemplateTableComponent, FormsModule],
  templateUrl: './tourist-template-view.component.html',
  styleUrl: './tourist-template-view.component.scss',
})
export class TouristTemplateViewComponent implements OnDestroy {
  selectedTourType = 'default';
  tourTemplates: TourTemplateResponseModel[] = [];

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private touristTemplateService: TouristTemplateService,
    private router: Router,
  ) {}

  private _locationInfo: { countryId: string; cityId: string };

  @Input()
  set locationInfo(value: { countryId: string; cityId: string }) {
    this._locationInfo = value;
    if (this._locationInfo?.countryId && this._locationInfo?.cityId) {
      this.getTourTemplates();
    }
  }

  // lifecycle hooks
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onTourTypeChange(event: Event): void {
    if (event) {
      this.selectedTourType = (event.target as HTMLSelectElement).value;
    }
    this.getTourTemplates();
  }

  getTourTemplates(): void {
    if (this.selectedTourType) {
      this.touristTemplateService
        .getTourTemplatesByType(this._locationInfo?.countryId, this._locationInfo?.cityId, this.selectedTourType)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res: TourTemplateResponseModel[]) => {
            this.tourTemplates = res;
          },
          error: async (error) => {
            console.error(error);
            await Swal.fire('Упс...', 'Помилка при отриманні шаблонів!', 'error');
          },
        });
    }
  }

  async addNewTouristTemplate(): Promise<void> {
    await this.router.navigate(['/tourist-info/add'], {
      queryParams: { countryId: this._locationInfo.countryId, cityId: this._locationInfo.cityId },
      queryParamsHandling: 'merge',
    });
  }
}
