import { IEventConfigModel } from '../../tabs/events-page/models/event-config.model';

export const multiLanguageTabsConfig: IEventConfigModel[] = [
  {
    Mode: 'input',
    Control: 'NameMultiLanguage',
    Label: 'Назва події',
    StyleClass: '',
  },
  {
    Mode: 'textarea',
    Control: 'ShortDescriptionMultiLanguage',
    Label: 'Короткий опис події',
    StyleClass: '',
    Rows: 5,
    Cols: 30,
  },
  {
    Mode: 'textarea',
    Control: 'LongDescriptionMultiLanguage',
    Label: 'Повний опис події',
    StyleClass: '',
    Rows: 8,
    Cols: 30,
  },
  {
    Mode: 'input',
    Control: 'EventAddressMultiLanguage',
    Label: 'Адреса події',
    StyleClass: '',
  },
];

export const multiTourTabsConfig: IEventConfigModel[] = [
  {
    Mode: 'input',
    Control: 'TitleMultiLanguage',
    Label: 'Назва події',
    StyleClass: '',
    Placeholder: 'Введіть назву',
  },
  {
    Mode: 'textarea',
    Control: 'DescriptionMultiLanguage',
    Label: 'Опис події',
    StyleClass: '',
    Rows: 5,
    Cols: 30,
    Placeholder: 'Придумайте опис',
  },
];
