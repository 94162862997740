import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { API } from 'aws-amplify';
import { TourTemplatePayloadModel, TourTemplateResponseModel } from '../models/tour-template.model';

@Injectable({
  providedIn: 'root',
})
export class TouristTemplateService {
  getTourTemplatesByType(countryId: string, cityId: string, type: string): Observable<TourTemplateResponseModel[]> {
    return from(
      API.get(
        'cityOnPalm-service',
        `/admin/ai-tour/templates?CountryId=${countryId}&CityId=${cityId}&Type=${type}`,
        {},
      ),
    );
  }

  getTourTemplateById(id: string): Observable<TourTemplateResponseModel> {
    return from(API.get('cityOnPalm-service', `/admin/ai-tour/templates/${id}`, {}));
  }

  createDefaultTemplate(countryId: string, cityId: string, tour: TourTemplatePayloadModel): Observable<{ Id: string }> {
    return from(
      API.post('cityOnPalm-service', `/admin/ai-tour/template?CountryId=${countryId}&CityId=${cityId}`, {
        body: tour,
      }),
    );
  }

  updateTourTemplate(id: string, tour: TourTemplatePayloadModel): Observable<{ Id: string }> {
    return from(
      API.patch('cityOnPalm-service', `/admin/ai-tour/templates/${id}`, {
        body: tour,
      }),
    );
  }

  deleteTemplate(userId: string, itemId: string): Observable<string> {
    return from(API.del('cityOnPalm-service', `/admin/ai-tour/template?UserId=${userId}&ItemId=${itemId}`, {}));
  }
}
