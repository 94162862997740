import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { lastValueFrom, Observable, of, Subject } from 'rxjs';
import {
  TouristLocationModel,
  TourTemplatePayloadModel,
  TourTemplateResponseModel,
} from '../../../../models/tour-template.model';
import { TouristLocationsPageService } from '../../../../services/tourist-locations-page.service';
import { Location, NgForOf, NgIf } from '@angular/common';
import { MultiTabsComponent } from '../multi-tabs/multi-tabs.component';
import { IEventConfigModel } from '../../../../../events-page/models/event-config.model';
import { InputTextModule } from 'primeng/inputtext';
import { TouristTemplateService } from '../../../../services/tourist-template.service';
import { IImageBaseModel } from '../../../../../../shared/models/i-image-upload.model';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SupportedLanguagesModel } from '../../../../../../shared/models/i-supported-languages.model';
import { ImageUploadComponent } from '../../../../../../shared/components/image-upload/image-upload.component';
import { EventsService } from '../../../../../../services/events.service';
import { TemplateFormModel } from '../../../../models/template-form.model';
import { EventMultiFields } from '../../../../../../shared/components/multi-language-tabs/multi-language-tabs.component';

import { supportedLanguagesConfig } from '../../../../../../shared/configs/supported-language.config';
import { multiTourTabsConfig } from '../../../../../../shared/configs/multi-langiage-tabs.config';
import { availableThemesConfig } from '../../../../../../shared/configs/theme.config';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-edit-tour-template',
  standalone: true,
  templateUrl: './add-edit-tour-template.component.html',
  styleUrls: ['./add-edit-tour-template.component.scss'],
  imports: [ReactiveFormsModule, NgIf, NgForOf, MultiTabsComponent, InputTextModule, ImageUploadComponent],
})
export class AddEditTourTemplate implements OnInit, OnDestroy {
  searchControl: FormControl = new FormControl('');
  filteredLocations: TouristLocationModel[] = [];
  selectedLocations: TouristLocationModel[] = [];
  loading = false;
  images: IImageBaseModel[] = [];
  templateForm: FormGroup<TemplateFormModel>;
  countryId: string | null = null;
  cityId: string | null = null;
  templateId: string | null = null;
  tourData: TourTemplateResponseModel;
  protected readonly availableThemes: string[] = availableThemesConfig;
  protected readonly multiLanguageTabs: IEventConfigModel[] = multiTourTabsConfig;
  protected readonly supportedLanguagesConfig: SupportedLanguagesModel[] = supportedLanguagesConfig;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private touristLocationsPageService: TouristLocationsPageService,
    private touristTemplateService: TouristTemplateService,
    private location: Location,
    private route: ActivatedRoute,
    private eventsService: EventsService,
  ) {}

  get displayedLocations(): TouristLocationModel[] {
    const unselected: TouristLocationModel[] = this.filteredLocations.filter(
      (location: TouristLocationModel) => !this.selectedLocations.includes(location),
    );
    return [...this.selectedLocations, ...unselected];
  }

  // lifecycle hooks
  ngOnInit(): void {
    this.initializeTourForm();
    this.initializeParams();
    this.initializeSearch();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleMultiLanguageDataChanged(data: EventMultiFields): void {
    this.templateForm.patchValue({
      TitleMultiLanguage: data.TitleMultiLanguage,
      DescriptionMultiLanguage: data.DescriptionMultiLanguage,
    });
  }

  isThemeSelected(theme: string): boolean {
    return this.templateForm.value.Categories?.includes(theme);
  }

  toggleTheme(theme: string): void {
    const currentCategories: string[] = this.templateForm.value.Categories || [];
    const updatedCategories: string[] = currentCategories.includes(theme)
      ? currentCategories.filter((category: string): boolean => category !== theme)
      : [...currentCategories, theme];

    this.templateForm.patchValue({ Categories: updatedCategories });
  }

  toggleLocation(location: TouristLocationModel): void {
    const isSelected: boolean = this.selectedLocations.some(
      (loc: TouristLocationModel): boolean => loc.ItemId === location.ItemId,
    );
    this.selectedLocations = isSelected
      ? this.selectedLocations.filter((loc: TouristLocationModel): boolean => loc.ItemId !== location.ItemId)
      : [...this.selectedLocations, location];
    this.templateForm.patchValue({ TourLocations: this.selectedLocations });
  }

  isLocationSelected(loc: TouristLocationModel): boolean {
    return this.selectedLocations.some((sel: TouristLocationModel): boolean => sel.ItemId === loc.ItemId);
  }

  navigateBack(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.location.back();
  }

  handleImagesChanged(images: IImageBaseModel[]): void {
    this.images = images;
  }

  handleImageDeleted(imageUrl: string): void {
    if (this.tourData) {
      const id: string = this.tourData.Id;
      this.eventsService.removeImageFromBucket(imageUrl, id).subscribe(async () => {
        this.templateForm.controls.S3Link.patchValue([]);
        await Swal.fire('Успіх!', 'Зображення успішно видалено!', 'success');
      });
    }
  }

  submitTourTemplateForm(): void {
    const locations: TouristLocationModel[] = this.templateForm.controls.TourLocations.value;

    const updatedLocations: any = locations.map((location: TouristLocationModel) => ({
      UserId: location.UserId,
      ItemId: location.ItemId,
      Id: location.Id,
      Location: `${location.Location.lat}#${location.Location.lng}`,
    }));

    this.templateForm.patchValue({ TourLocations: updatedLocations });

    const templateData: TourTemplatePayloadModel = this.templateForm.value as TourTemplatePayloadModel;
    this.templateId ? this.updateTourTemplate(templateData) : this.createTourTemplate(templateData);
  }

  private async saveTourTemplate(request: Observable<any>, successMessage: string): Promise<void> {
    try {
      const res = await lastValueFrom(request);
      await this.uploadImages(res.Id);
      await Swal.fire('Успіх!', successMessage, 'success');
      this.templateForm.reset();
      this.navigateBack();
    } catch {
      await Swal.fire('Упс...', 'Помилка при обробці запиту!', 'error');
      this.navigateBack();
    }
  }

  private async uploadImages(templateId: string): Promise<void> {
    if (!this.images?.length) {
      return;
    }

    try {
      await lastValueFrom(this.eventsService.uploadImageToBucket(this.images, templateId));
      await Swal.fire('Успіх!', 'Зображення додано!', 'success');
    } catch {
      await Swal.fire('Упс...', 'Помилка при додаванні зображення!', 'error');
    }
  }

  private async createTourTemplate(data: TourTemplatePayloadModel): Promise<void> {
    await this.saveTourTemplate(
      this.touristTemplateService.createDefaultTemplate(this.countryId, this.cityId, data),
      'Шаблон успішно створено',
    );
  }

  private async updateTourTemplate(data: TourTemplatePayloadModel): Promise<void> {
    await this.saveTourTemplate(
      this.touristTemplateService.updateTourTemplate(this.templateId, data),
      'Шаблон успішно оновлено',
    );
  }

  private initializeTourForm(): void {
    this.templateForm = this.fb.group<TemplateFormModel>({
      TitleMultiLanguage: new FormControl({}, [Validators.required]),
      DescriptionMultiLanguage: new FormControl({}, [Validators.required]),
      TourLocations: new FormControl([], [Validators.required]),
      Duration: new FormControl(30, [Validators.required]),
      Categories: new FormControl([], [Validators.required]),
      S3Link: new FormControl([], [Validators.required]),
    });
  }

  private getAndPatchTourTemplate(id: string): void {
    this.touristTemplateService.getTourTemplateById(id).subscribe((data: TourTemplateResponseModel) => {
      this.templateForm.patchValue({
        TitleMultiLanguage: data.TitleMultiLanguage,
        DescriptionMultiLanguage: data.DescriptionMultiLanguage,
        TourLocations: data.TourLocations,
        Duration: data.Duration,
        Categories: data.Categories,
      });

      this.tourData = data;
      this.selectedLocations = data.TourLocations;
    });
  }

  private initializeParams(): void {
    this.route.paramMap.subscribe((params: ParamMap): void => {
      this.templateId = params.get('id') || null;
      if (this.templateId) {
        this.getAndPatchTourTemplate(this.templateId);
      }
    });

    this.route.queryParamMap.subscribe((queryParams: ParamMap): void => {
      this.countryId = queryParams.get('countryId') || null;
      this.cityId = queryParams.get('cityId') || null;
    });
  }

  private initializeSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((query: string) => {
          if (!query.trim()) {
            this.filteredLocations = [];
            return of([]);
          }
          this.loading = true;
          return this.touristLocationsPageService.searchTouristLocations(this.countryId, this.cityId, query);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (locations) => {
          const results = locations || [];
          this.filteredLocations = results.filter(
            (location: TouristLocationModel) => !this.isLocationSelected(location),
          );

          this.loading = false;
        },
        error: (error) => {
          console.error('Search tour error:', error);
          this.loading = false;
        },
      });
  }
}
