<ng-container *ngIf="tourTemplateList">
  <table class="table table-striped mt-3">
    <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Фото</th>
        <th scope="col">Назва</th>
        <th scope="col">Статус</th>
        <th scope="col">Дата створення</th>
        <th *ngIf="templateType !== 'custom'" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let template of tourTemplateList">
        <td>{{ template.Id }}</td>
        <td>
          <mat-icon *ngIf="template.S3Link.length > 0; else else_block">check</mat-icon>
          <ng-template #else_block>—</ng-template>
        </td>
        <td>{{ template.TitleMultiLanguage.uk || template.TitleMultiLanguage }}</td>
        <td>{{ statusParams[template.Status] }}</td>
        <td>{{ template.DateCreated }}</td>
        <td *ngIf="templateType !== 'custom'">
          <button (click)="deleteTour(template.UserId, template.ItemId)" class="mat-raised-button custom-btn">
            <mat-icon class="pointer"> delete</mat-icon>
          </button>
          <button (click)="navigateToTemplateDetails(template.Id)" class="mat-raised-button custom-btn">
            <mat-icon class="pointer"> edit</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
