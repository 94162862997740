<div class="tourist-location-wrapper">
  <button (click)="addNewTouristLocation()" class="btn btn-danger mt-5 mb-2 mr-3">Додати подію</button>

  <app-import-tourist-locations />

  <p>
    Загальна кількість туристичних місць: <b>{{ touristLocations.length || 0 }}</b>
  </p>

  <app-location-table (updateLocations)="getAllLocations()" [touristLocations]="touristLocations" />
</div>
