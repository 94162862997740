import { Component } from '@angular/core';

@Component({
  selector: 'app-tourist-info-tab',
  templateUrl: './tourist-info-tab.component.html',
  styleUrls: ['./tourist-info-tab.component.scss'],
})
export class TouristInfoTabComponent {
  countryId: string;
  cityId: string;
  activeTabIndex = 0;

  onCountrySelected(countryId: string): void {
    this.countryId = countryId;
  }

  onCitySelected(cityId: string): void {
    this.cityId = cityId;
  }

  onTabChange(index: number): void {
    this.activeTabIndex = index;
  }
}
