import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { API } from 'aws-amplify';
import { IImageSignedUrlRequestObjectModel } from '../models/i-image-signed-url-request-object.model';
import { catchError } from 'rxjs/operators';
import { UpdateTourLocationModel } from '../models/UpdateTourLocationModel';
import { AddNewTourLocationModel } from '../models/AddNewTourLocation.model';

@Injectable()
export class TouristLocationsPageService {
  public getTouristLocations(CountryId: string, CityId: string): Observable<any> {
    return from(
      API.get('cityOnPalm-service', `/admin/touristLocations/get?CountryId=${CountryId}&CityId=${CityId}`, {}),
    );
  }

  public searchTouristLocations(CountryId: string, CityId: string, Query: string): Observable<any> {
    return from(
      API.get(
        'cityOnPalm-service',
        `/admin/touristLocations/search?countryId=${CountryId}&cityId=${CityId}&query=${Query}`,
        {},
      ),
    );
  }

  public addTouristLocation(newTourLocation: AddNewTourLocationModel): Observable<any> {
    return from(
      API.post('cityOnPalm-service', '/admin/touristLocations/add', {
        body: newTourLocation,
      }),
    ).pipe(
      catchError((err) => {
        console.log(err);
        return throwError(err);
      }),
    );
  }

  public updateTouristLocation(updatedTourLocation: UpdateTourLocationModel): Observable<any> {
    return from(
      API.post('cityOnPalm-service', '/admin/touristLocations/update', {
        body: updatedTourLocation,
      }),
    );
  }

  public deleteTouristLocation(locationToDelete: { UserId: string; ItemId: string }): Observable<any> {
    return from(
      API.del('cityOnPalm-service', '/admin/touristLocations/delete', {
        body: locationToDelete,
      }),
    );
  }

  public getImageSignedURLToUploadImage(
    urlRequestObject: IImageSignedUrlRequestObjectModel,
  ): Observable<{ Key: string; UploadURL: string }> {
    return from(
      API.post('cityOnPalm-service', '/admin/touristLocations/generateImageSignedURL', {
        body: urlRequestObject,
      }),
    );
  }

  importTouristLocation(newTourLocations: any): Observable<any> {
    return from(
      API.post('cityOnPalm-service', '/admin/touristLocations/import', {
        body: newTourLocations,
      }),
    ).pipe(
      catchError((err) => {
        console.log(err);
        return throwError(err);
      }),
    );
  }
}
