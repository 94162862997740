import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IImageBaseModel } from '../../models/i-image-upload.model';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class ImageUploadComponent implements OnInit {
  @Output() imagesChanged = new EventEmitter<IImageBaseModel[]>();
  @Output() imageDeleted = new EventEmitter<string>();
  preview: IImageBaseModel[] = [];
  urlPreview: string[] = [];
  fileUploadEnabled = true;
  protected readonly bucketLink = environment.amplifyConfig.Storage.AWSS3.bucketUrl;

  private _images: string[];

  get images(): string[] | undefined {
    return this._images;
  }

  @Input()
  set images(value: string[]) {
    this._images = value;
    this.initializeData();
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData(): void {
    if (this._images) {
      this.urlPreview = this._images.map((item) => item.replace('_admin', '_admin_small'));
      this.checkFileLimit();
    }
  }

  readImage(event: Event): void {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;
    const list: FileList | null = inputElement.files;

    if (list && list.length > 0 && this.urlPreview.length + this.preview.length < 1) {
      const image = list[0];

      const imageObject = {
        type: image.type,
        base64: '',
      };
      this.preview.push(imageObject);
      this.checkFileLimit();

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        imageObject.base64 = reader.result as string;
        this.emitImages();
      };
    }
  }

  removeImage(index: number): void {
    this.preview.splice(index, 1);
    this.emitImages();
    this.checkFileLimit();
  }

  removeS3Image(index: number): void {
    const removedImage = this.urlPreview[index];
    this.urlPreview.splice(index, 1);
    this.emitImages();
    this.imageDeleted.emit(removedImage);
    this.checkFileLimit();
  }

  emitImages(): void {
    this.imagesChanged.emit(this.preview);
  }

  checkFileLimit(): void {
    this.fileUploadEnabled = this.urlPreview.length + this.preview.length < 1;
  }
}
