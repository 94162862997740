<div class="p-4">
  <h1 class="font-medium">{{ templateId ? 'Редагування шаблону' : 'Створення шаблону' }}</h1>
  <form (ngSubmit)="submitTourTemplateForm()" [formGroup]="templateForm">
    <app-image-upload
      (imageDeleted)="handleImageDeleted($event)"
      (imagesChanged)="handleImagesChanged($event)"
      [images]="tourData?.S3Link || []"
    />

    <app-multi-tabs
      (dataChanged)="handleMultiLanguageDataChanged($event)"
      [initialData]="templateForm.value"
      [multiLanguageConfig]="multiLanguageTabs"
      [supportedLanguages]="supportedLanguagesConfig"
    />
    <div class="flex flex-column gap-1 p-4">
      <label class="cp-input-label" for="search">Які локації хочете відвідати?</label>
      <input [formControl]="searchControl" id="search" pInputText placeholder="Пошук локацій" type="text" />
      <div *ngIf="loading">
        <p>Завантаження...</p>
      </div>
      <ul>
        <li *ngFor="let loc of displayedLocations" class="location-variants">
          <input (change)="toggleLocation(loc)" [checked]="isLocationSelected(loc)" type="checkbox" />
          {{ loc.TitleMultiLanguage['uk'] }}
        </li>
      </ul>
    </div>

    <div class="col">
      <label for="duration">Скільки повинен тривати тур?</label>
      <input formControlName="Duration" id="duration" max="240" min="30" step="10" type="range" />
      <label>{{ templateForm.controls.Duration.value }} хв</label>
    </div>

    <div class="col">
      <label for="chips">Ви вже маєте якусь тему для туру? (Виберіть хоча б одну)</label>
      <div class="flex flex-wrap gap-2" id="chips">
        <span
          (click)="toggleTheme(theme)"
          *ngFor="let theme of availableThemes"
          [class.selected]="isThemeSelected(theme)"
          class="theme-chip"
        >
          {{ theme }}
        </span>
      </div>
    </div>

    <button class="btn btn-success mr-2" type="submit">{{ templateId ? 'Оновити' : 'Створити' }}</button>
    <button (click)="navigateBack($event)" class="btn btn-danger">Назад</button>
  </form>
</div>
