import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupportedLanguagesModel } from '../../../../../../shared/models/i-supported-languages.model';
import { IEventConfigModel } from '../../../../../events-page/models/event-config.model';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LanguageISOCodesType } from '../../../../../../shared/models/i-language-iso.model';
import { EventMultiFields } from '../../../../../../shared/components/multi-language-tabs/multi-language-tabs.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NgForOf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-multi-tabs',
  standalone: true,
  imports: [InputTextModule, InputTextareaModule, NgForOf, NgSwitchCase, ReactiveFormsModule, TabViewModule, NgSwitch],
  templateUrl: './multi-tabs.component.html',
  styleUrl: './multi-tabs.component.scss',
})
export class MultiTabsComponent implements OnInit {
  @Input() supportedLanguages: SupportedLanguagesModel[] = [];
  @Input() multiLanguageConfig: IEventConfigModel[] = [];
  @Output() dataChanged = new EventEmitter<EventMultiFields>();
  multiLanguageForm: FormGroup;
  activeIndex = 0;

  constructor(private fb: FormBuilder) {
    this.multiLanguageForm = this.fb.group({});
  }

  private _initialData: EventMultiFields;

  @Input()
  set initialData(value: EventMultiFields) {
    this._initialData = value;
    if (value) {
      this.multiLanguageForm.patchValue({
        TitleMultiLanguage: value.TitleMultiLanguage,
        DescriptionMultiLanguage: value.DescriptionMultiLanguage,
      });
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  updateParentFormField(): void {
    this.dataChanged.emit(this.multiLanguageForm.value);
  }

  private initForm(): void {
    this.multiLanguageConfig.forEach((configItem: IEventConfigModel) => {
      const group = new FormGroup({});
      this.supportedLanguages.forEach((language: SupportedLanguagesModel) => {
        const controlData = this.initialData?.[`${configItem.Control}`];
        const languageCode = language.code as LanguageISOCodesType;
        const initialValue = controlData?.[languageCode] || '';
        const control = new FormControl(initialValue);
        group.addControl(language.code, control);
      });
      this.multiLanguageForm.addControl(configItem.Control, group);
    });
  }
}
