import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TourTemplateResponseModel } from '../../../../models/tour-template.model';
import { MatIcon } from '@angular/material/icon';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TouristTemplateService } from '../../../../services/tourist-template.service';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

enum statusParams {
  'done' = 'Завершено',
  'in-progress' = 'В процесі',
  'active' = 'Активна',
}

@Component({
  selector: 'app-tourist-template-table',
  standalone: true,
  imports: [MatIcon, NgForOf, NgIf, TranslateModule],
  templateUrl: './tourist-template-table.component.html',
  styleUrl: './tourist-template-table.component.scss',
})
export class TouristTemplateTableComponent {
  @Input() tourTemplateList: TourTemplateResponseModel[];
  @Output() updateTemplates = new EventEmitter<void>();

  readonly statusParams = statusParams;

  constructor(
    private touristTemplateService: TouristTemplateService,
    private router: Router,
  ) {}

  private _templateType: string | null = null;

  get templateType(): string | null {
    return this._templateType;
  }

  @Input()
  set templateType(value: string | null) {
    this._templateType = value;
  }

  deleteTour(userId: string, itemId: string): void {
    Swal.fire({
      title: 'Ви впевнені?',
      text: 'Видалений елемент не можна буде відновити!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Так, видалити!',
      cancelButtonText: 'Скасувати',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const encodedUserId: string = encodeURIComponent(userId);
        const encodedItemId: string = encodeURIComponent(itemId);

        this.touristTemplateService.deleteTemplate(encodedUserId, encodedItemId).subscribe({
          next: async () => {
            await Swal.fire('Успіх!', 'Елемент успішно видалено!', 'success');
            this.updateTemplates.emit();
          },
          error: async () => {
            await Swal.fire('Упс...', 'Помилка при видаленні елемента!', 'error');
            this.updateTemplates.emit();
          },
        });
      }
    });
  }

  async navigateToTemplateDetails(id: string): Promise<void> {
    await this.router.navigate(['/tourist-info', id], { queryParamsHandling: 'preserve' });
  }
}
