import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TouristInfoTabComponent } from './tourist-info-tab.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { TouristLocationViewComponent } from './features/tourist-location-view/tourist-location-view.component';
import { TouristTemplateViewComponent } from './features/tourist-template-view/tourist-template-view.component';
import { LocationSelectorComponent } from '../../shared/components/location-selector/location-selector.component';
import { AddEditTourTemplate } from './features/tourist-template-view/features/add-edit-tour-template/add-edit-tour-template.component';

const routes: Routes = [
  { path: '', component: TouristInfoTabComponent, data: { title: 'Туристична інформація' } },
  { path: 'add', component: AddEditTourTemplate, data: { title: 'Створити шаблон' } },
  { path: ':id', component: AddEditTourTemplate, data: { title: 'Редагувати шаблон' } },
];

@NgModule({
  declarations: [TouristInfoTabComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    MatTabsModule,
    TranslateModule.forChild(),
    TouristLocationViewComponent,
    TouristTemplateViewComponent,
    LocationSelectorComponent,
  ],
  exports: [TouristInfoTabComponent],
})
export class TouristInfoTabModule {}
