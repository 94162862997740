import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AddEditTouristLocation } from '../add-edit-tourist-location/add-edit-tourist-location';
import { takeUntil } from 'rxjs/operators';
import { NewTouristLocation } from '../../../../models/newTouristLocation';
import { TouristLocation } from '../../../../models/touristLocations';
import { TouristLocationsPageService } from '../../../../services/tourist-locations-page.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { NgForOf, NgIf } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-location-table',
  standalone: true,
  imports: [MatIcon, TranslateModule, NgForOf, NgIf],
  templateUrl: './location-table.component.html',
  styleUrl: './location-table.component.scss',
})
export class LocationTableComponent implements OnDestroy {
  @Input() touristLocations: TouristLocation[] = [];
  @Output() updateLocations = new EventEmitter<void>();

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private touristLocationsPageService: TouristLocationsPageService,
    private dialog: MatDialog,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  deleteTourLocation(UserId: string, ItemId: string): void {
    this.touristLocationsPageService
      .deleteTouristLocation({ UserId, ItemId })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: async (response) => {
          if (response) {
            await Swal.fire('Успіх!', 'Видалено!', 'success');
          } else {
            await Swal.fire('Упс...', 'Виникла не передбачувана ситуація! Зверніться до адміністратора!', 'error');
          }
          this.updateLocations.emit();
        },
        error: async () => {
          await Swal.fire('Упс...', 'Помилка при видаленні туристичного об`єкта!', 'error');
          this.updateLocations.emit();
        },
      });
  }

  editTourLocation(touristLocation: TouristLocation): void {
    this.dialog
      .open(AddEditTouristLocation, {
        width: '50vw',
        data: {
          touristLocation,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newLocationData: NewTouristLocation) => {
        this.updateLocations.emit();
      });
  }
}
