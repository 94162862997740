<div class="tourist-template-wrapper">
  <div class="flex-row justify-content-center align-items-center">
    <button (click)="addNewTouristTemplate()" class="btn btn-success mr-3">
      {{ 'Створити шаблон' }}
    </button>

    <select (change)="onTourTypeChange($event)" [(ngModel)]="selectedTourType" class="select-type" name="tourType">
      <option [value]="'default'">Дефолтні тури</option>
      <option [value]="'custom'">Кастомні тури</option>
    </select>
  </div>

  <p class="mt-5">
    Загальна кількість турів: <b>{{ tourTemplates.length || 0 }}</b>
  </p>

  <app-tourist-template-table
    (updateTemplates)="getTourTemplates()"
    [templateType]="selectedTourType"
    [tourTemplateList]="tourTemplates"
  />
</div>
