import { Component, Input, OnDestroy } from '@angular/core';
import { ImportTouristLocationsComponent } from '../../../../shared/components/import-tourist-locations/import-tourist-locations.component';
import { MatIcon } from '@angular/material/icon';
import { takeUntil } from 'rxjs/operators';
import { TouristLocation } from '../../models/touristLocations';
import { TouristLocationsPageService } from '../../services/tourist-locations-page.service';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AddEditTouristLocation } from './features/add-edit-tourist-location/add-edit-tourist-location';
import { NewTouristLocation } from '../../models/newTouristLocation';
import { MatDialog } from '@angular/material/dialog';
import { LocationTableComponent } from './features/location-table/location-table.component';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-tourist-location-view',
  standalone: true,
  imports: [ImportTouristLocationsComponent, MatIcon, TranslateModule, LocationTableComponent],
  templateUrl: './tourist-location-view.component.html',
  styleUrl: './tourist-location-view.component.scss',
})
export class TouristLocationViewComponent implements OnDestroy {
  touristLocations: TouristLocation[] = [];
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private touristLocationsPageService: TouristLocationsPageService,
    private dialog: MatDialog,
  ) {}

  private _locationInfo: { countryId: string; cityId: string };

  get locationInfo(): { countryId: string; cityId: string } {
    return this._locationInfo;
  }

  @Input()
  set locationInfo(value: { countryId: string; cityId: string }) {
    this._locationInfo = value;
    if (this._locationInfo?.countryId && this._locationInfo?.cityId) {
      this.getAllLocations();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addNewTouristLocation(): void {
    this.dialog
      .open(AddEditTouristLocation, {
        width: '50vw',
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newLocationData: NewTouristLocation) => {
        this.getAllLocations();
      });
  }

  getAllLocations(): void {
    this.touristLocationsPageService
      .getTouristLocations(this._locationInfo.countryId, this._locationInfo.cityId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          this.touristLocations = res;
        },
        error: async (error) => {
          console.error(error);
          await Swal.fire('Упс...', 'Помилка при отриманні локацій!', 'error');
        },
      });
  }
}
