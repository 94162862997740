<app-location-selector (citySelected)="onCitySelected($event)" (countrySelected)="onCountrySelected($event)">
</app-location-selector>

<mat-tab-group (selectedIndexChange)="onTabChange($event)">
  <mat-tab label="{{ 'Туристичні Локації' }}">
    <ng-container *ngIf="activeTabIndex === 0">
      <app-tourist-location-view [locationInfo]="{ countryId, cityId }" />
    </ng-container>
  </mat-tab>
  <mat-tab label="{{ 'Туристичні Шаблони' }}">
    <ng-container *ngIf="activeTabIndex === 1">
      <app-tourist-template-view [locationInfo]="{ countryId, cityId }" />
    </ng-container>
  </mat-tab>
</mat-tab-group>
